/**
 * Any common methods for sliders could go here!
 */
export class AbstractCarousel {
    constructor(rootNode) {
        if (new.target === AbstractCarousel) {
            throw new TypeError("Cannot construct Abstract instances directly");
        }

        this.$root = rootNode;
    }
}
