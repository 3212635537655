import Accordion from "accordion/src/accordion.mjs"

// ----------------
// Accordion Modules
// ----------------
export default function init() {
    let accordion = document.querySelectorAll('[data-allura-accordion]');
    accordion.forEach((el) => {
        // This will tell us the type of accordion to create
        let type = el.dataset.alluraAccordion;
        let acc = null;
        if( type === 'DocumentationAccordion' ) {
            
            new Accordion(el, {
                noTransforms: true,
                modal: true,
            })
        }
        // else if..
    })
}
