export default function init() {
    const mainAddressField = document.querySelector("[data-autocomplete='address']");
    const addressFields = document.querySelectorAll("[data-autocomplete]");
    if (!mainAddressField) {
        return null;
    }

    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBgTOWF_K2rLqKO2o0TQDsZ4fCMhFZwDt0&libraries=places&callback=googleReady';
    script.defer = true;

    window.googleReady = () => {
        initAutocomplete()
    };
    document.head.appendChild(script);

    let autocomplete;


    function initAutocomplete() {
        autocomplete = new google.maps.places.Autocomplete(
            mainAddressField,
            { types: ["geocode"] }
        );
        autocomplete.setFields(["address_component"]);
        autocomplete.addListener("place_changed", fillInAddress);

        google.maps.event.addDomListener(mainAddressField, 'keydown', function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
            }
        });
    }

    function fillInAddress() {
        // Get the place details from the autocomplete object.
        const place = autocomplete.getPlace();

        let addressData = {
            address1: '',
            city: '',
            state: '',
            zip: ''
        }
        for (const component of place.address_components) {
            const addressType = component.types[0];
            if (addressType === 'street_number') {
                addressData.address1 = component.long_name + addressData.address1
            }
            if (addressType === 'route') {
                addressData.address1 = addressData.address1 + ' ' + component.short_name
            }
            if (addressType === 'locality') {
                addressData.city = component.long_name
            }
            if (addressType === 'administrative_area_level_1') {
                addressData.state = component.short_name
            }
            if (addressType === 'postal_code') {
                addressData.zipcode = component.short_name
            }
        }
        addressFields.forEach((addressField) => {
            if (!addressField.dataset || !addressField.dataset.autocomplete) {
                return
            }
            let autoCompleteTargetProperty = addressField.dataset.autocomplete
            if (addressData.hasOwnProperty(autoCompleteTargetProperty)) {
                addressField.value = addressData[autoCompleteTargetProperty]
            }
        });
    }
}
