// ----------------
// Swiper Modules
// ----------------
import { VideoTestimonials } from '../../carousels/VideoTestimonials';
import { VideoCarousel } from '../../carousels/VideoCarousel';

function init() {
    let $sliders = document.querySelectorAll('[data-allura-slider]')
    let sliders = [];
    $sliders.forEach(($element) => {
        // This will tell us the type of slider to create
        let type = $element.dataset.alluraSlider;
        let slider = null;
        if( type === 'VideoTestimonials' ) {
            slider = new VideoTestimonials($element);
        }
        else if( type === 'VideoCarousel' ) {
            slider = new VideoCarousel($element);
        }
        // else if( type === '' ) {
        //
        // }
        if (slider) {
            sliders.push(slider.sliderObj);
        }
    })
}
export default init
