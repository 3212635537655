let hamburgers, activeHamClass, asideHeading;

// Ham Toggle class
activeHamClass = 'ham-is-active';

asideHeading = document.querySelector('[data-aside-heading]')

// Hamburgers
hamburgers = Array.from(document.querySelectorAll('[data-install-hamburger]'));

function triggerCallback(event) {
    hamburgers.forEach((ham) => {
        asideHeading.classList.toggle(activeHamClass);
    });
}

function init() {
    if( hamburgers.length !== 0 ) {
        hamburgers.forEach((ham) => {
            ham.addEventListener('click', triggerCallback);
        });
    }
}

export default init
