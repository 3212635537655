// ----------------
// UI Modules - Global
// ----------------
// This is UI modules for Global instances
// ---------------------------------------------------------------
import headerSearch from './headerSearch';
import ham from './ham';

function init() {
    headerSearch()
    ham()
}

export default init
