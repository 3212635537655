import Headroom from 'headroom.js'

/**
 * Element to initiate headroom on
 * @param header
 * @return Headroom
 */

export default (header) => {
    const headroom  = new Headroom(header);
    headroom.offset = 130;
    headroom.tolerance.up = 5;
    headroom.init();
    return headroom;
}
