let asideHeading, headerSearchInput, headerSearchTrigger, activeSearchClass;
// Active class
activeSearchClass = 'search-is-active';

asideHeading = document.querySelector('[data-aside-heading]')

function triggerCallback(event) {
    if( !asideHeading.classList.contains(activeSearchClass) ) {
        return expandSearch();
    } else if ( asideHeading.classList.contains(activeSearchClass) ) {
        return hideSearch();
    }
}

function expandSearch() {
    asideHeading.classList.add(activeSearchClass);
    setTimeout(setInputFocus, 300);
}

function setInputFocus() {
    headerSearchInput.focus();
}

function hideSearch() {
    asideHeading.classList.remove(activeSearchClass);
    headerSearchInput.blur();
}

function init() {
    if( asideHeading !== null ) {
        // Search input
        headerSearchInput = document.querySelector('[data-header-search-input]');
        // Search trigger
        headerSearchTrigger = document.querySelectorAll('[data-header-search-trigger]');

        headerSearchTrigger.forEach((trigger) => {
            trigger.addEventListener('click', triggerCallback);
        });
    }
}

export default init
