function init() {
    const show = function (elem) {
        elem.classList.remove('hidden');
    };

    const hide = function (elem) {
        elem.classList.add('hidden');
    };

    const toggle = function (elem) {
        elem.classList.toggle('hidden');
    };

    document.addEventListener('click', function (event) {
        if (!event.target.matches('[data-readmore]')) return;
        event.preventDefault();
        const clicked = event.target;
        const data = clicked.dataset;
        const targetId = data.readmore;
        if (targetId) {
            const target = document.getElementById(targetId);
            if (target) {
                toggle(target);
                clicked.remove();
            }
        }

    }, false);
}
export default init
