import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// polyfills
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'picturefill';


import '../css/app.css'

import * as components from './components'

import {t} from "./utils/filters";
import Vue from 'vue';
Vue.filter('t', t);
import CookieBanner from "../vue/CookieBanner/CookieBanner.vue";

// Set background images lazily
document.addEventListener('lazybeforeunveil', (e) => {
    const bg = e.target.getAttribute('data-bg');
    if(bg){
        e.target.style.backgroundImage = `url(${bg})`;
    }
});

// App main - don't add heavy stuff here.
const main = async() => {
    components.globalJS();
};

// Execute async function
main().then((value) => {
});


new Vue({
    components: {
        CookieBanner: CookieBanner
    }
}).$mount('#cookie-banner');
