// ----------------
// UI Modules - Global
// ----------------
// This is UI modules for Global instances
// ---------------------------------------------------------------
import headroom from './headroom'
import headerSearch from './headerSearch';
import headerHam from './ham';
import installGuideModules from './installGuide'
import smoothScroll from './smoothScroll'
import glightbox from './glightbox'
import swiperModules from './swiper'
import readmore from './readmore'
// import rellax from './rellax'
import accordion from './accordion'
import addressAutocomplete from "./addressAutocomplete";

function init() {
    headroom(document.querySelector('header'));
    headerSearch();
    headerHam();
    installGuideModules();
    smoothScroll();
    glightbox();
    swiperModules()
    // rellax()
    accordion()
    readmore()
    addressAutocomplete()
}

export default init
