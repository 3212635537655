import {
    Swiper,
    Autoplay,
    Keyboard,
    EffectFade,
    A11y
} from 'swiper/swiper.esm.js';

Swiper.use([Autoplay, Keyboard, EffectFade, A11y]);
// Use modules

import {
    AbstractCarousel
} from './AbstractCarousel';

export class VideoTestimonials extends AbstractCarousel {
    /**
     *
     * @param HTMLElement rootElement
     */
    constructor(rootNode, options = {}) {
        super(rootNode);

        this.settings = {
            videoTestimonials: {
                spaceBetween: 0,
                touchRatio: 0,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                autoplay: {
                    delay: 10000,
                },
                keyboard: {
                    enabled: true,
                },
                breakpoints: {
                    // when window width is <= 1023px
                    1023: {
                        keyboard: {
                            enabled: false,
                        },
                    },
                    // when window width is <= 767px
                    767: {
                        slidesPerView: 1,
                        touchRatio: 1,
                    }
                }
            },
        };

        Object.assign(this.settings, options);
        // Since we're extending an "AbstractCarousel", we have to call super()
        // before "this" is available.

        // Store swiper elements in a var
        this.$tabs = this.$root.querySelector('[data-slider]');

        // Init Each Swipers
        this.slider = new Swiper(this.$tabs, this.settings.videoTestimonials);
    }
}
