<template>
    <div>
        <vue-cookie-accept-decline
                @clicked-accept="cookieClickedAccept"
                @clicked-decline="cookieClickedDecline"
                :debug="false"
                position="bottom"
                element-id="allura"
                ref="cookiebanner"
                type="bar">
            <div slot="message">
                <div class="hidden md:block">
                    {{"We use cookies to personalize content and ads, to provide social media features and to analyze our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information, that you’ve provided to them or that they’ve collected from your use of their services."|t}}
                </div>
                <div class="block md:hidden">
                    <div v-if="textExerpt">
                        {{"We use cookies to personalize content..."|t}}
                    </div>
                    <div v-if="!textExerpt">
                        {{"We use cookies to personalize content and ads, to provide social media features and to analyze our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information, that you’ve provided to them or that they’ve collected from your use of their services."|t}}
                    </div>
                    <button @click="this.toggleContent" type="button" class="underline">
                        <span v-if="textExerpt" class="leading-tight flex items-center text-white text-xxs">LEARN MORE <svg class="ml-1 fill-current -mt-1" width="10px" height="7px" viewBox="0 0 10 7" xmlns="http://www.w3.org/2000/svg"><path d="M83.7088623 729.253906L92.1730957 729.253906 88.03479 736.067261z" transform="translate(-83.000000, -729.000000)" fill-rule="evenodd"/></svg></span>
                        <span v-if="!textExerpt" class="leading-tight flex items-center text-white text-xxs">SHOW LESS <svg class="ml-1 fill-current -mt-1" width="10px" height="8px" viewBox="0 0 10 8" xmlns="http://www.w3.org/2000/svg"><path transform="translate(-83.000000, -729.000000) translate(87.940979, 732.660583) scale(1, -1) translate(-87.940979, -732.660583)" d="M83.7088623 729.253906L92.1730957 729.253906 88.03479 736.067261z" fill-rule="evenodd"/></svg></span>
                    </button>
                </div>
            </div>
            <div slot="declineContent">
                {{"DON'T ACCEPT"|t}}
            </div>

            <div slot="acceptContent">
                {{"ACCEPT"|t}}
            </div>
        </vue-cookie-accept-decline>
    </div>
</template>

<script>
    import VueCookieAcceptDecline from 'vue-cookie-accept-decline';

    export default {
        components: {
            VueCookieAcceptDecline
        },
        data() {
            return {
                textExerpt: true 
            }
        },
        name: 'CookieBanner',
        methods: {
            toggleContent() {
                this.textExerpt = !this.textExerpt
            },
            cookieClickedAccept() {
                let d = new Date();
                d.setTime(d.getTime() + (24*60*60*1000*365));
                let expires = "expires="+ d.toUTCString();
                document.cookie = "CookieConsent=accepted;" + expires + ";path=/";
            },
            cookieClickedDecline() {
                let d = new Date();
                d.setTime(d.getTime() + (24*60*60*1000*365));
                let expires = "expires="+ d.toUTCString();
                document.cookie = "CookieConsent=declined;" + expires + ";path=/";
            }
        },
        mounted() {
        },
        beforeMount() {
        },
    }
</script>

<style type="text/postcss">
    .cookie__bar {
        @apply bg-orient overflow-hidden w-full z-50 items-center px-5 py-5;
        z-index: 9999;
    }

    .cookie__bar--bottom {
        @apply fixed bottom-0 left-0 right-0;
    }

    .cookie__bar__wrap {
        @apply mx-auto flex flex-col items-center;
        @screen sm {
            max-width: theme('screens.sm');
        }
        @screen md {
            max-width: theme('screens.md');
        }
        @screen lg {
            max-width: theme('screens.lg');
        }
        @screen xl {
            max-width: theme('screens.xl');
        }
    }

    @media (min-width: 768px) {
        .cookie__bar__wrap {
            flex-direction: row;
        }
    }

    .cookie__bar__content {
        @apply text-white flex-grow w-full;
    }

    .cookie__bar__buttons {
        @apply flex w-full text-right justify-start mt-6;
    }

    .cookie__bar__buttons__button {
        display: inline-block;
    }

    .cookie__bar__buttons__button:hover {
        cursor: pointer;
        text-decoration: none;
    }

    .cookie__bar__buttons__button--accept {
        @apply ml-4 cursor-pointer py-5 px-8 align-middle inline-flex text-center items-center;
        box-sizing: border-box;

        @apply bg-white font-bold text-xs text-shark;
    }

    .cookie__bar__buttons__button--decline {
        @apply cursor-pointer py-5 px-8 align-middle inline-flex text-center items-center;
        box-sizing: border-box;

        @apply bg-lochmara-dark font-bold text-xs text-white;
    }

    @screen md {
        .cookie__bar {
            @apply py-10;
        }

        .cookie__bar__buttons {
            @apply ml-4 justify-end mt-0;
        }

        .cookie__bar__content {
            @apply w-auto;
        }

        .cookie__bar__buttons__button--decline {
            @apply ml-4;
        }
    }
</style>