import {
    Swiper,
    Navigation,
    Keyboard,
    A11y
} from 'swiper/swiper.esm.js';

Swiper.use([Navigation, Keyboard, A11y]);
// Use modules

import {
    AbstractCarousel
} from './AbstractCarousel';

export class VideoCarousel extends AbstractCarousel {
    /**
     *
     * @param HTMLElement rootElement
     */
    constructor(rootNode, options = {}) {
        super(rootNode);

        this.settings = {
            param: {
                spaceBetween: 0,
                touchRatio: 0,
                keyboard: {
                    enabled: true,
                },
                navigation: {
                    nextEl: '.video-carousel-nav-next',
                    prevEl: '.video-carousel-nav-prev',
                },
                breakpoints: {
                    // when window width is <= 1023px
                    1023: {
                        touchRatio: 1,
                        keyboard: {
                            enabled: false,
                        },
                    },
                }
            },
        };

        Object.assign(this.settings, options);
        // Since we're extending an "AbstractCarousel", we have to call super()
        // before "this" is available.

        // Store swiper elements in a var
        this.$swiperContainer = this.$root.querySelector('[data-swiper-container]');

        // Init Each Swipers
        this.slider = new Swiper(this.$swiperContainer, this.settings.param);
    }
}
