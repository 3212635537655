let hamburgers, activeHamClass, header;

// Ham Toggle class
activeHamClass = 'ham-active';
// Hamburgers
hamburgers = Array.from(document.querySelectorAll('[data-allura-hamburger]'));
// Header
header = document.querySelector('.header');

function triggerCallback(event) {
    hamburgers.forEach((ham) => {
        header.classList.toggle(activeHamClass);
    });
}

function init() {
    if( hamburgers.length !== 0 ) {
        hamburgers.forEach((ham) => {
            ham.addEventListener('click', triggerCallback);
        });
    }
}

export default init
